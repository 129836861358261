import React, { useState } from "react";
import { Container } from "./style"
import EditOutlined from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import EditBudgetSummary from "../Modal/EditBudgetSummary/EditBudgetSummary";

const QuotationSummary = ({ summary, due }) => {
    const [openModalEditQuote, setOpenModalEditQuote] = useState(false);

    const handleEditQuotation = () => {
        setOpenModalEditQuote(true);
    }

    return (
        <Container>
            <div className="summary-header">
                <p className="title-small">Resumo da cotação
                    <IconButton onClick={handleEditQuotation}> <EditOutlined fontSize="small" sx={{ color: "#fff" }} /></IconButton>
                </p>
                <p className="title-medium">R$ {due}</p>
            </div>

            <div className="summary-content">
                {summary.map((format, i) => {
                    return (
                        <div key={i} className="format-list">
                            <p className="label-medium">{format.formatName.toUpperCase()}</p>
                            <ul className="body-small">
                                <li>{format.formatTypeQuantity} peças de {format.formatType.toLowerCase()}</li>
                            </ul>
                        </div>
                    )
                })}
            </div>

            <EditBudgetSummary open={openModalEditQuote} handleClose={(e) => { setOpenModalEditQuote(false) }} />
        </Container>
    )
}

export default QuotationSummary;