import { styled } from '@mui/system';
import { styled as styledc } from 'styled-components';
import { Paper } from '@mui/material';
import colors from "../../utils/colors.json"

export const JobHeaderContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid  ${colors.light_gray}`,
    borderRadius: '10px',
    padding: '15px',
    backgroundColor: 'transparent',
    width: '100%',
    "a": {
        color: colors.noir
    }
}));

export const InfoBox = styledc.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    gap: 5
});

export const StatusContainer = styledc.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5
});
