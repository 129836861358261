import React, { useEffect, useState } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import JobHeader from "../../components/JobHeader/JobHeader";
import { Link, useParams } from "react-router-dom"
import JobBody from "../../components/JobBody/JobBody";
import DeleteBudgetAlertModal from "../../components/Modal/DeleteBudgetAlertModal/DeleteBudgetAlertModal";
import { Breadcrumbs } from "@mui/material";
import api from "../../services/api";
import { StatusProvider } from "../../contexts/StatusContext";

const JobPage = () => {
    const [openModalDeleteBudget, setOpenModalDeleteBudget] = useState(false);
    const [jobData, setJobData] = useState(null);
    const { companyName, jobId } = useParams();

    // const handleOpenDeleteModal = () => {
    //     setOpenModalDeleteBudget(true);
    // }

    useEffect(() => {
        var jobWithBlobInfos = '/Job/GetJobWithBlobInfos/'
        var formData = {
            "jobId": jobId
        }

        api.post(jobWithBlobInfos, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setJobData(data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    return (
        <StatusProvider>
            <Container>
                <PageHeader>
                    <Breadcrumbs>
                        <Link to={'/empresas/' + companyName}>
                            {companyName}
                        </Link>
                    </Breadcrumbs>
                    <Link to={"/empresas/google"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>
                </PageHeader>

                <JobHeader job={jobData} />
                <JobBody job={jobData} />

                <DeleteBudgetAlertModal
                    open={openModalDeleteBudget}
                    handleClose={() => { setOpenModalDeleteBudget(false) }}
                />
            </Container>
        </StatusProvider>
    )
}

export default JobPage;