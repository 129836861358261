import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import BudgetsTable from "../../components/BudgetsTable/BudgetsTable";
import SearchBar from "../../components/SearchBar/SearchBar";
import api from "../../services/api";

const BudgetsListPage = () => {
    const [budgets, setBudgets] = useState([]);

    useEffect(() => {
        var allBudgetsURL = '/Job/GetJobsWithBudgetStatus'

        api.get(allBudgetsURL).then((response) => {
            return response.data;
        }).then((data) => {
            setBudgets(data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    return (
        <Container>
            <PageHeader>
                <p className="headline-small"><u>Budgets</u></p>
                <SearchBar placeholder="Pesquise um budget" />
            </PageHeader>
            <BudgetsTable budgets={budgets} />
        </Container>
    )
}

export default BudgetsListPage;