import React, { useEffect, useState } from "react";
import { List, ListItem, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import colors from "../../utils/colors.json";
import SpecItem from "./SpecItem.jsx";

const FormatItem = ({ specsList, setFormatBudget }) => {
    const [specs, setSpecs] = useState([]);

    const addSpec = () => {
        setSpecs([...specs, {
            id: specs.length > 0 ? (specs[specs.length - 1] && specs[specs.length - 1].id + 1) : 0,
            formatId: specsList[0].formatId,
            amount: 1,
            total: 0
        }]);
    };

    useEffect(() => {
        setFormatBudget(specs);
    }, [specs])

    return (
        <List sx={{ padding: 0 }}>
            {specs.map((spec) => (
                <SpecItem
                    key={spec.id}
                    id={spec.id}
                    sx={{ gap: 2, alignItems: "center", justifyContent: "space-between", paddingRight: 1 }}
                    spec={spec}
                    options={specsList}
                    specs={specs}
                    setSpecs={setSpecs}
                />
            ))}
            {specs.length === specsList.length ? null :
                <ListItem onClick={addSpec} sx={{ gap: 0, alignItems: "center", justifyContent: "start", paddingRight: 1 }}>
                    <Button startIcon={<AddCircleOutlineIcon />} sx={{
                        color: colors.noir,
                        fontWeight: 600,
                    }}>
                        adicionar especificação
                    </Button>
                </ListItem>}
        </List>
    );
}

export default FormatItem;
