import React from 'react';
import '@mui/material/Paper';
import { StyledTableCellHead, StyledTableContainer } from '../DefaultTable/DefaultTable';
import BudgetListRow from '../BudgetListRow/BudgetListRow';

import {
    Table,
    TableBody,
    TableHead,
    TableRow
} from '@mui/material';

const BudgetsTable = ({budgets}) => {

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCellHead variant='no-outline'>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Nome</p>
                            </div>
                        </StyledTableCellHead >
                        <StyledTableCellHead variant='no-outline'>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Data de criação</p>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead variant='no-outline'>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Cliente</p>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead variant='no-outline'>
                            <div className='flex-row'>
                                <p className={"title-medium"}>{'   '}</p>
                            </div>
                        </StyledTableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {budgets.map((budget, i) => (
                        <BudgetListRow budget={budget} key={i}/>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default BudgetsTable;
