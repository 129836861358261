import React, { useState } from "react";
import { Container, AddButton } from "./style"
import TermRow from "../TermRow/TermRow"
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AddTermModal from "../Modal/TermModal/AddTermModal";

var terms = [{
    title: "Objeto do contrato",
    text: "Este contrato tem como objetivo a produção de peças digitais, incluindo vídeos, gráficos, áudio e outros materiais conforme descrito no anexo A"
}, {
    title: "Prazo de entrega",
    text: "A produtora se compromete a entregar todas as peças digitais até a data de [data de entrega], conforme o cronograma estabelecido no anexo B."
}, {
    title: "Direitos autorais",
    text: "A produtora cede ao contratante todos os direitos autorais sobre as peças digitais produzidas, incluindo os direitos de reprodução, distribuição e exibição."
},]

const BudgetTerms = () => {
    const [openAddModal, setOpenAddModal] = useState(false);

    const handleAddNewTerm = (e) => {
        setOpenAddModal(true);
    }

    return (
        <Container>
            <div className="terms-header">
                <p className="title-small">Legal / Cláusulas</p>
            </div>

            <div className="terms-content">
                {terms.map((t, i) => {
                    return <TermRow id={i} key={i} title={t.title} text={t.text} />
                })}

                <AddButton onClick={handleAddNewTerm}>
                    <AddCircleOutlineRoundedIcon fontSize="small" />
                    <u className="title-small">Adicionar Cláusula</u>
                </AddButton>
            </div>

            {openAddModal && <AddTermModal open={openAddModal} handleClose={() => { setOpenAddModal(false) }} />}
        </Container>
    )
}

export default BudgetTerms;