import React from "react";
import styled from "styled-components";
import { Instagram, LinkedIn } from '@mui/icons-material';

const FooterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    
    .social-icons{
        display: flex;
        gap: 10px;
    }
`
const Footer = () => {

    return (
        <FooterContainer>
            <p className="title-small">Replica faz parte <br /> de <u>Landscape</u></p>

            <div className="social-icons">
                <Instagram />
                <LinkedIn />
            </div>
        </FooterContainer>
    )
}

export default Footer