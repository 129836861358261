import React, { useState } from 'react';
import Accordion from "./Accordion.jsx"
import { Skeleton } from "@mui/material"
import data from "./table.json"
import { PaperCustom } from './style.jsx';

const BudgetCalculator = () => {
    const [expanded, setExpanded] = useState(false);
    const [formats, setFormats] = useState([...data]);

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <>
            {formats.length > 0 ?
                <PaperCustom
                    elevation={0}
                    variant="outlined"
                >
                    {formats && formats.map((format, i) => {
                        return <Accordion key={i} id={i} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} format={format} />
                    })} </PaperCustom>
                :
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={window.innerWidth < 500 ? '100%' : '40%'}
                    height={window.innerWidth < 500 ? '50vh' : '90%'}
                />
            }
        </>
    );
}

export default BudgetCalculator;