import React, { useState } from "react";
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions, Backdrop, CircularProgress } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import TextFieldComp from "../../TextFieldComp/TextFieldComp";
import api from "../../../services/api";

const RequestSendModal = ({ open, handleClose, resend, ...props }) => {
    const [openBackdrop, setOpenBackdrop] = useState();
    const [formData, setFormData] = useState({
        clientName: "Cliente0.893635365354934",
        jobLink: "",
        jobMessage: "",
    })

    const handleSendRequest = () => {
        setOpenBackdrop(true);
        var sendRequestURL = "/Job/SendBudgetAssetsResendingEmail";

        api.post(sendRequestURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Email enviado com sucesso!") {
                handleClose();
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handleChangeMessage = (e) => {
        setFormData({
            ...formData,
            jobMessage: e.target.value,
            jobId: props.job.id,
            jobName: props.job.name,
            jobStatus: props.job.status,
            clientEmail: props.job.clientEmail,
        })
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-large">
                Solicitar {resend ? 'reenvio' : 'envio'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ padding: 0 }}>
                <p className="title-medium" style={{ margin: 0 }}>
                    Descreva os materiais que precisam ser {resend ? 'reenviados' : 'enviados'}:
                </p>
                <TextFieldComp
                    label=''
                    name={'jobMessage'}
                    fullWidth={true}
                    multiline
                    rows={4}
                    value={formData.jobMessage}
                    sx={{ paddingTop: '5px' }}
                    onChange={handleChangeMessage}
                />
                <p className="label-medium" style={{ marginTop: 5 }}>Um e-mail será enviado para o endereço vinculado a esse job.</p>
            </DialogContent>

            <DialogActions sx={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                <CustomButton onClick={handleSendRequest} variant={"contained"} sx={{ width: '50%' }}>
                    <span className="title-small">
                        Solicitar
                    </span>
                </CustomButton>
            </DialogActions>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default RequestSendModal;