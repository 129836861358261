import React, { useState } from "react";
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import SelectCompDefault from "../../SelectCompDefault/SelectCompDefault";
import TextFieldComp from "../../TextFieldComp/TextFieldComp";
import AddUserToCompanyAlert from "../AddUserToCompanyAlert/AddUserToCompanyAlert";

const companies = [
    { name: 'Google' },
    { name: 'Sports Bet' },
    { name: 'Natura' },
    { name: 'Mc Donalds' },
    { name: 'Itaú' },
];

const AddUserToCompany = ({ open, handleClose, resend, ...props }) => {
    const [selectCompany, setSelectCompany] = useState("");
    const [newCompany, setNewCompany] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const handleAddNewCompany = () => {
        setNewCompany(true);
    }

    const handleBackToCompanies = () => {
        setNewCompany(false);
    }
    
    const handleOpenAlert = () => {
        setOpenAlert(true);
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-large">
                Adicionar usuário á uma empresa
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent sx={{ padding: 0, display: 'flex', flexDirection: 'column', gap: 3 }}>
                <p className="title-medium" style={{ margin: 0 }}>
                    Usuário: <span className="thin">liandra@google.com</span>
                </p>

                {newCompany ?
                    <TextFieldComp fullWidth={'true'} placeholder={'Nome'} label={'Nova empresa:'} />
                    :
                    <SelectCompDefault labelKey={'name'} valueKey={'name'} label={'Empresas cadastradas:'} options={companies} typeId={selectCompany} setTypeId={setSelectCompany} />
                }

            </DialogContent>


            <DialogActions sx={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>

                {newCompany ?
                    <CustomButton onClick={handleBackToCompanies} variant={"outlined"} sx={{ width: '50%' }}>
                        <span className="title-small">
                            Voltar
                        </span>
                    </CustomButton>
                    :
                    <CustomButton onClick={handleAddNewCompany} variant={"outlined"} sx={{ width: '50%' }}>
                        <span className="title-small">
                            + Nova empresa
                        </span>
                    </CustomButton>
                }

                <CustomButton onClick={handleOpenAlert} variant={"contained"} sx={{ width: '50%' }}>
                    <span className="title-small">
                        Solicitar
                    </span>
                </CustomButton>
            </DialogActions>

            <AddUserToCompanyAlert open={openAlert} handleClose={() => { setOpenAlert(false) }} />
        </Dialog>
    )
}

export default AddUserToCompany;