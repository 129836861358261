import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import colors from "../../utils/colors.json"

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        boxShadow: '0px 1px 3px 1px #00000026',
        borderRadius: '50px', // Rounded borders
        backgroundColor: '#f5f5f5', // Background color for the input
        '& fieldset': {
            borderColor: 'transparent', // Remove border
        },
        '&:hover fieldset': {
            borderColor: '#ccc', // Add slight border color on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ccc', // Border color when focused
        },
    },
    '& .MuiInputBase-input': {
        padding: '12px 14px', // Padding inside the input
    },
});

const SearchBar = ({placeholder}) => {
    return (
        <CustomTextField
            className='body-medium'
            variant="outlined"
            placeholder={placeholder}
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment position="end" sx={{ backgroundColor: '#434343', borderRadius: 9999, padding: '10px 5px' }}>
                            <SearchIcon sx={{ color: colors.white }} />
                        </InputAdornment>
                    ),
                }
            }}
        />
    );
};

export default SearchBar;
