import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json', // Make sure you specify the content type if sending JSON
  },
  withCredentials: true,
});

export default api;