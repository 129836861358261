import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 30px 0;
    gap: 20px;
`
export const PageHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    *{
        cursor: pointer;
    }

    a{
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: underline;
        text-transform: capitalize;
    }

    p:hover, a:hover{
        font-weight: 500 !important;
    }

    a:visited, a:active{
        color: initial;
    }
`