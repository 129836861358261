import { styled } from 'styled-components';
// import colors from "../../utils/colors.json"

export const ContainerRow = styled.div`
   display: flex;
   padding: 5px 10px;
   align-items: center;

   .title-term{
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: 200px;

    p{   
        font-weight: 500 !important;
    }
   }
`; 