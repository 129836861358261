import styled from "styled-components";
import { Paper } from "@mui/material";

export const LoginPaper = styled(Paper)`
    width: 30%;
    max-width: 500px;
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 30px;
    margin-top: auto;
    border-radius: 10px !important;
`
export const AuthForm = styled.form`
    width: 100%;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 25px;
`