import React, { useState } from "react";
import { IconButton, Switch, Tooltip, CircularProgress, Backdrop } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { VersionRow, Summary, VersionInfo, ActionButtons, Details } from './style'
import BudgetTerms from "../BudgetTerms/BudgetTerms";
import QuotationSummary from "../QuotationSummary/QuotationSummary";
import CustomButton from "../CustomButton/CustomButton"
import DeleteVersionModal from "../Modal/DeleteVersionModal/DeleteVersionModal";
import SendVersionFeedback from "../Modal/SendVersionFeedback/SendVersionFeedback";
import api from "../../services/api"

const BudgetVersionAccordion = ({ jobId, version }) => {
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleOpenDeleteModal = (e) => {
        e.preventDefault()
        setOpenDeleteModal(true);
    }
    const handleSendVersionEmail = (e) => {
        setOpenBackdrop(true);
        var sendVersionURL = '/Budget/SendBudgetVersionEmail/';
        var formData = {
            "jobId": jobId,
            "budgetVersionId": version.budgetVersionId,
            "clientEmail": "liandraammonteiro@gmail.com",
            "clientName": "Liandra"
        }

        api.post(sendVersionURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Email enviado com sucesso!") {
                setOpenBackdrop(false);
                setOpenFeedback(true);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleSwitchCurrentBudget = (e) => {
        e.preventDefault()
        var setActiveURL = '/Budget/SetActiveBudget/';
        var formData = {
            "jobId": jobId,
            "budgeVersionId": version.budgetVersionId,
        }

        api.post(setActiveURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Alteração realizada com sucesso!") {
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <VersionRow variant="outlined" expanded={isExpanded}>
            <Summary
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <VersionInfo onClick={() => { setIsExpanded(!isExpanded) }}>
                    <p className='title-small'>Versão {version.version}</p>|
                    <p className='title-small'>{version.createdAt}</p>
                </VersionInfo>

                <ActionButtons>
                    <Tooltip arrow title={'Baixar versão'}>
                        <IconButton aria-label="download">
                            <FileDownloadOutlined />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip arrow title={'Duplicar versão'}>
                        <IconButton aria-label="copy">
                            <ContentCopyOutlined />
                        </IconButton>
                    </Tooltip> */}
                    {/* <Tooltip arrow title={'Deletar versão'}>
                        <IconButton aria-label="delete" onClick={handleOpenDeleteModal}>
                            <DeleteOutlined />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip arrow title={'Ativar versão'}>
                        <Switch checked={version.isActive} color="success" onChange={handleSwitchCurrentBudget} />
                    </Tooltip>
                </ActionButtons>
            </Summary>
            <Details>
                <QuotationSummary summary={version.budgetResume} due={version.due} />
                <BudgetTerms />
                <CustomButton onClick={handleSendVersionEmail} variant={"contained"} sx={{ alignSelf: "end" }}>
                    <span className="title-small">Enviar versão por e-mail</span>
                </CustomButton>
            </Details>

            {openBackdrop &&
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={openBackdrop}
                    onClick={() => { setOpenBackdrop(false) }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>}
            {openFeedback && <SendVersionFeedback open={openFeedback} handleClose={() => { setOpenFeedback(false) }} />}
            {openDeleteModal && <DeleteVersionModal open={openDeleteModal} handleClose={() => { setOpenDeleteModal(false) }} />}
        </VersionRow>
    )
}

export default BudgetVersionAccordion