import React from 'react';
import { ReactComponent as RIcon } from '../../icons/r-vector.svg';
import { Container, Links } from './style';
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Navbar() {
  let location = useLocation();
  var auth =
    location.pathname === '/login' ||
      location.pathname === '/meu-perfil' ||
      location.pathname === '/redefinir-senha'
      ? false : true;

  return (
    <Container>
      <div className={"navbar-wrapper"}>
        <div className={"icons"}>
          <RIcon />
          {auth && <div className='IconName title-small'>
            GP
          </div>}
        </div>

        {auth &&
          <Links>
            <a href="/budgets" className={`title-small ${location.pathname.includes('budgets') && 'active'}`}>BUDGETS</a>
            <a href="/empresas" className={`title-small ${location.pathname.includes('empresas') && 'active'}`}>EMPRESAS</a>
          </Links>}

        {auth &&
          <div className='user-mail'>
            <p className={`title-small`}>gp@landscape.com</p>
            <KeyboardArrowDownIcon />
          </div>}
      </div>
    </Container>
  );
}
