import React from 'react';
import { InfoBox, BudgetContainer } from './style'
// import CustomButton from '../CustomButton/CustomButton';
// import AddUserToCompany from "../Modal/AddUserToCompany/AddUserToCompany";

const BudgetHeader = ({ budget }) => {
    // const [openAddToCompany, setOpenAddToCompany] = useState(false);

    // const handleOpenModal = () => {
    //     setOpenAddToCompany(true)
    // }

    return (
        <BudgetContainer variant={"outlined"}>
            <InfoBox>
                <p className='label-medium'>
                    {budget.createdAt}
                </p>
                <p className='headline-small'>
                    Budget Nº {budget.name}
                </p>
                <p className='label-medium'>
                    criado por {budget.clientEmail}
                </p>
            </InfoBox>

            {/* <CustomButton onClick={handleOpenModal} variant="outlined">
                <span className='title-small'> Adicionar à uma empresa</span>
            </CustomButton> */}

            {/* <AddUserToCompany open={openAddToCompany} handleClose={() => { setOpenAddToCompany(false) }} /> */}
        </BudgetContainer>
    );
};

export default BudgetHeader;
