import React from 'react';
import '@mui/material/Paper';
import { StyledTableCellHead, StyledTableCell, StyledTableRow, StyledTableContainer } from '../DefaultTable/DefaultTable';
import Status from '../Status/Status';
import { Search, TodayRounded, FilterAltOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import {
    Table,
    IconButton, TableBody,
    TableHead,
    TableRow
} from '@mui/material';

const JobTable = ({ jobs }) => {
    const navigate = useNavigate();

    const handleOpenJob = (id) => {
        navigate(id.toString());
    }

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCellHead sx={{ padding: '5px 20px !important' }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Jobs</p>
                                <IconButton sx={{ color: '#fff' }}>
                                    <Search fontSize='small' /></IconButton>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead sx={{ padding: '5px 20px !important' }} className={"title-medium"}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Data de criação</p>
                                <IconButton sx={{ color: '#fff' }}>
                                    <TodayRounded fontSize='small' /></IconButton>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead sx={{ padding: '5px 20px !important' }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Status</p>
                                <IconButton sx={{ color: '#fff' }}>
                                    <FilterAltOutlined fontSize='small' /></IconButton>
                            </div>
                        </StyledTableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {jobs.map((job) => (
                        <StyledTableRow onClick={(e) => { handleOpenJob(job.id) }} key={job.name}>
                            <StyledTableCell className='body-large'>{job.name}</StyledTableCell>
                            <StyledTableCell className='body-large'>{job.createdAt}</StyledTableCell>
                            <StyledTableCell className='body-large'>
                                <Status status={job.status}>{job.status}</Status>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default JobTable;
