import React from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import TextFieldComp from '../../TextFieldComp/TextFieldComp'
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "./style"

const AddTermModal = ({ open, handleClose }) => {

    const handleAdd = () => {
        // request delete
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalTitle className="title-large">
                Adicionar Cláusula
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent>
                <TextFieldComp label='Título' fullWidth={true} type='text' />
                <TextFieldComp label='Texto' fullWidth={true} multiline rows={6} sx={{ paddingTop: '5px' }} />
            </ModalContent>

            <ModalActions>
                <CustomButton sx={{ minWidth: '50%' }} onClick={handleAdd} variant={"contained"}>
                    <span className="title-small">
                        Salvar
                    </span>
                </CustomButton>
            </ModalActions>
        </DialogModal>
    )
}

export default AddTermModal;