import React, { useContext } from 'react';
import '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { Divider, ListItemButton } from '@mui/material';
import { CustomList } from './style';
import { CompanyContext } from '../../contexts/CompanyContext';

const CompaniesTable = () => {
    const navigate = useNavigate();
    const { companyList } = useContext(CompanyContext)

    const handleOpenBudget = (company) => {
        navigate(company.name.toLowerCase(), { state: { companyId: company.id } });
    }

    return (
        <CustomList>
            {companyList.map((company, i) => (
                <div key={company.id} >
                    <ListItemButton
                        className='body-large'
                        onClick={() => { handleOpenBudget(company) }}
                        sx={{ padding: '15px 20px' }}>
                        {company.name}
                    </ListItemButton>
                    {i < companyList.length - 1 && <Divider />}
                </div>
            ))}
        </CustomList>
    );
};

export default CompaniesTable;
