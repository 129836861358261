import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 30px 0;
    gap: 30px;
`
export const PageHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`