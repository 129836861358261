import React, { useContext, useEffect, useState } from "react";
import { Container, PageHeader } from "./style";
import JobTable from "../../components/JobTable/JobTable";
import { useParams } from 'react-router-dom';
import { EditOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { CompanyContext } from "../../contexts/CompanyContext";

const JobsListPage = () => {
    const [jobs, setJobs] = useState([]);
    const { getIdFromName, companyList } = useContext(CompanyContext);
    const { companyName } = useParams();

    useEffect(() => {
        if (companyList.length > 0) {
            var jobsFormCompany = '/Job/GetJobsByCompany/';
            var formData = { companyId: getIdFromName(companyName) }

            api.post(jobsFormCompany, formData).then((response) => {
                return response.data;
            }).then((data) => {
                setJobs(data)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [companyList])

    return (
        <Container>
            <PageHeader>
                <Link to={"/empresas"} className="title-small">
                    <ArrowBack /> Voltar
                </Link>
            </PageHeader>

            <PageHeader>
                <div className="company-title">
                    <p className="headline-small">{companyName}</p>
                    <IconButton>
                        <EditOutlined sx={{ color: "#000" }} />
                    </IconButton>
                </div>
                {/* <CustomButton variant={"contained"}>
                    Gerenciar acessos
                </CustomButton> */}
            </PageHeader>
            <JobTable jobs={jobs} companyName={companyName} />
        </Container>
    )
}

export default JobsListPage;