import React, { useState } from "react";
import { Add } from "@mui/icons-material"
import { Container, PageHeader } from "./style";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
import SearchBar from "../../components/SearchBar/SearchBar";
import CreateCompanyModal from "../../components/Modal/CreateCompanyModal/AddTermModal";

const CompanyListPage = () => {
    const [openCreateModal, setOpenCreateModal] = useState(false);

    const handleOpenCreateCompany = () => {
        setOpenCreateModal(true)
    }

    return (
        <Container>
            <PageHeader>
                <p className="headline-small">Empresas {" "}
                    <div onClick={handleOpenCreateCompany} className="add-button">
                        <Add sx={{ color: '#fff' }} />
                    </div></p>
                <SearchBar placeholder={"Pesquise uma empresa"} />
            </PageHeader>
            <CompaniesTable />
            <CreateCompanyModal open={openCreateModal} handleClose={() => { setOpenCreateModal(false) }} />
        </Container>
    )
}

export default CompanyListPage;