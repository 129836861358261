import React, { createContext, useEffect, useState } from 'react';
import api from '../services/api';
export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
        var allCompaniesURL = '/Company/GetAllCompanies'

        api.get(allCompaniesURL).then((response) => {
            return response.data;
        }).then((data) => {
            setCompanyList(data);
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const getIdFromName = (name) => {
        try {
            var obj = companyList.filter((company) => company.name.toLowerCase() === name)
            return obj[0].id
        } catch (error) {
        }
    };

    return (
        <CompanyContext.Provider value={{ getIdFromName, companyList }}>
            {children}
        </CompanyContext.Provider>
    );
};
