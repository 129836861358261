import React, { useEffect, useState } from "react"
import { StyledTableRow, StyledTableCell } from "../DefaultTable/DefaultTable"
import { useNavigate } from 'react-router-dom';
import CustomButton from "../CustomButton/CustomButton";
import AddUserToCompany from "../Modal/AddUserToCompany/AddUserToCompany";

const BudgetListRow = ({ budget }) => {
    const navigate = useNavigate();
    const [isEmail, setIsEmail] = useState(false);
    const [openAddToCompany, setOpenAddToCompany] = useState(false);

    const handleOpenBudget = () => {
        navigate(budget.id.toString());
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleEmailChange = (string) => {
        if (emailRegex.test(string)) {
            setIsEmail(true);
        } else {
            setIsEmail(false);
        }
    };

    const handleAddToCompany = () => {
        setOpenAddToCompany(true);
    };

    useEffect(() => {
        handleEmailChange(budget.client);
    }, [])

    return (
        <StyledTableRow>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>{`Budget Nº` + budget.jobDescription}</StyledTableCell>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>{budget.createdAt}</StyledTableCell>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>{budget.companyName}</StyledTableCell>
            <StyledTableCell sx={{ width: '300px', textAligh: 'center' }} className='body-large' variant='no-outline'>
                {isEmail &&
                    <CustomButton onClick={handleAddToCompany} variant={'outlined'}>Adicionar á uma empresa</CustomButton>}
            </StyledTableCell>
            <AddUserToCompany open={openAddToCompany} handleClose={() => { setOpenAddToCompany(false) }} />
        </StyledTableRow>
    )
}

export default BudgetListRow;