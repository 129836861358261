import React, { useState } from "react";
import { ContainerRow } from "./style";
import { EditOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material";
import EditTermModal from "../Modal/TermModal/EditTermModal";

const TermRow = ({ id, title, text }) => {
    const [openEditTerm, setOpenEditTerm] = useState(false);

    const handleOpenEditTerm = () => {
        setOpenEditTerm(true);
    }

    return (
        <ContainerRow>
            <div className="title-term">
                <p className="label-medium">
                    {id + 1}. {title}
                </p>
                <IconButton onClick={handleOpenEditTerm}>
                    <EditOutlined fontSize="small" sx={{ color: "#000" }} />
                </IconButton>
            </div>
            <p className="body-small">
                {text}
            </p>

            <EditTermModal
                open={openEditTerm}
                handleClose={(e) => { setOpenEditTerm(false) }}
                term={{ title: title, text: text }} />
        </ContainerRow>
    )
}

export default TermRow;