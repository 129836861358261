import React from 'react';
import styled from 'styled-components';
import colors from "../../utils/colors.json"

const Label = styled.div`
  padding: 5px 15px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => colors[props.status]};
  text-transform: capitalize;
  width: 150px;
  text-align: center;

  ${props => props.sx}
`;

const Status = ({ status, ...props }) => {
    return (
        <Label className='body-medium' status={status} {...props}>
            {status}
        </Label>
    );
};

export default Status;
